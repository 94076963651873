.resume-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
    text-decoration: none;
    color: inherit;
    font-family: 'Raleway', sans-serif;
  }
  
  .resume-icon span {
    margin-top: 10px;
  }
  