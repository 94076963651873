.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  display: flex;
  backdrop-filter: blur(4px);
  align-items: center;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 20;
  padding: 5px;
  transition: background-color 0.3s ease;
}

.navbar:hover {
  background-color: #000;
}

.navbar-toggle {
  display: none;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 20;
}

.navbar-toggle:focus {
  outline: none;
}

.navbar-toggle .bar {
  width: 2rem;
  height: 0.25rem;
  background-color: #fff;
  border-radius: 10px;
  transition: all 0.3s linear;
  position: relative;
  transform-origin: 1px;
}

.navbar ul {
  display: flex;
  justify-content: center;
  width: 100%;
  list-style: none;
}

.navbar li {
  padding: 0 1rem;
}

.navbar a {
  color: #fff;
  text-decoration: none;
  letter-spacing: 2px;
  font-size: clamp(0.8rem, 3vw, 1rem);
}

.navbar a:hover,
.navbar a:focus {
  color: #f8f1f1;
  outline: none;
}

.navbar-icons {
  display: flex;
  justify-content: flex-end;
}

.navbar-icon {
  margin: 0 0.5rem;
}

@media (max-width: 768px) {
  .navbar-toggle {
    display: flex;
  }

  .navbar ul {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 60px;
    left: 0;
    background-color: #000;
    transition: transform 0.3s ease-in-out;
    transform: translateX(100%);
  }

  .navbar ul.open {
    transform: translateX(0);
  }

  .navbar li {
    padding: 2rem;
  }

  .navbar a {
    font-size: clamp(1.5rem, 4vw, 2rem);
  }

  .navbar-icons {
    flex-direction: row;
    justify-content: center;
  }
}

.no-scroll {
  overflow: hidden;
}
