html, body {
  height: 100vh;
  overflow: hidden;
}

.home {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  position: relative;
  max-width: 100%;
  height: 100%;
  font-family: 'Raleway', sans-serif; 
}

.home-icons {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 2em;
  width: max-content;
  z-index: 10;
}

.home-icons a {
  display: flex;
  align-items: center;
  padding: 10px;
  transition: padding 0.3s ease;
  color: #000;
  text-decoration: none;
  width: max-content;
  overflow: hidden;
}

.home-icons a span {
  margin-left: 10px;
  visibility: hidden;
  opacity: 0;
  transform: translateX(-100%);
  transition: 
    visibility 0s 0.3s, 
    opacity 0.3s ease, 
    transform 0.3s ease;
  white-space: nowrap;
}

.home-icons a:hover span {
  visibility: visible;
  opacity: 1;
  transform: translateX(0);
  transition-delay: 0s, 0s, 0s;
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(2px);
}


@media (max-width: 768px) {
  .home-icons {
    display: none;
  }
}

.plexus-background {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.home-content {
  z-index: 10;
  color: #000;  
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100% - 60px);
}

.home-content h1 {
  font-size: clamp(3em, 5vw, 7em);
  font-weight: 700;
}

.home-content h2 {
  font-size: clamp(2em, 3.5vw, 3.3em);
  font-weight: 600;
}

.home-content h3 {
  font-size: clamp(1.75em, 3vw, 3em);
  font-weight: 600;
}

.home-content h4 {
  font-size: clamp(1.25em, 2.5vw, 2em);
  font-weight: 600; 
}
