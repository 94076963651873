* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body, #root, .App {
  height: 100vh;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
}