body {
  overflow-y: auto;
  margin: 0;
  padding: 0;
}

.resume {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    justify-content: flex-start;
    min-height: calc(100vh - 60px);
    font-family: 'Raleway', sans-serif; 
  }
  
  .plexus-background {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  
  .resume-container {
    z-index: 10;
    position: relative;
    max-width: 80%;
    margin: auto;
    height: 100%;
    padding-top: 60px;

  }

  .resume-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .resume-content h1 {
    font-size: clamp(2.5em, 5vw, 5em);
    font-weight: 700;
  }
  
  .resume-icons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding: 20px 0;
    background-color: rgba(200, 200, 200, 0.5);
    border-radius: 50px;
    backdrop-filter: blur(5px);
  }
  
  .resume-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
    text-decoration: none;
    color: inherit;
  }
  
  .resume-icon span {
    margin-top: 10px;
  }
  
  .resume-pdf {
    display: flex;
    justify-content: center;
    padding: 20px 0;
  }
  
  .resume-pdf iframe {
    width: 80%;
    height: 70vh;
  }
  
  @media (max-width: 768px) {
    .resume-pdf iframe {
      width: 100%;
    }
  }
  